@tailwind base;
@tailwind components;
@tailwind utilities;

.shantell-sans-font {
  font-family: 'Shantell Sans', cursive;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: 'BNCE' 0, 'INFM' 0, 'SPAC' 0;
}

.figtree-font {
  font-family: 'Figtree', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.truncate-multiline {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word; /* Ensure long words are broken properly */
}

body {
  font-family: 'Figtree', sans-serif;
  /* .no-spinners {
    -moz-appearance: textfield;
  }

  .no-spinners::-webkit-outer-spin-button,
  .no-spinners::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  } */
}

@layer base {
  :root {
    --color-theme: #ffffff;
    --color-black: #000000;
    --color-bgBase: #f7f9fb;
    --color-content: #1c1c1d;
    --color-grey: #7e7e7e;
    --color-grey20: #8f95b2;
    --color-grey30: #969696;
    --color-grey40: #c2c2c2;
    --color-greyLite: #eaeaea;
    --color-primary: #7c57ff;
    --color-primary10: #a193ff;
    --color-primaryLite: #f4f3ff;
    --color-primary-0: #f2eeff;
    --color-primary-1: #e5ddff;
    --color-primary-2: #cbbcff;
    --color-primary-3: #b09aff;
    --color-primary-4: #9679ff;
    --color-primary-5: #7c57ff;
    --color-primary-6: #6546d5;
    --color-primary-7: #4e34ab;
    --color-primary-8: #382382;
    --color-primary-9: #211158;
    --color-warning-50: #fef6e7;
    --color-warning-75: #fbe2b7;
    --color-warning-500: #dd900d;
    --color-warning-600: #ad6f07;
    --color-warning-800: #664101;
    --color-success-600: #04802e;
    --color-grey-0: #eaeaea;
    --color-grey-1: #d1d1d1;
    --color-grey-2: #c2c2c2;
    --color-grey-3: #b3b3b3;
    --color-grey-4: #969696;
    --color-grey-5: #787878;
    --color-grey-6: #5b5b5b;
    --color-grey-7: #3d3d3d;
    --color-grey-8: #313131;
    --color-grey-9: #252525;
    --color-grey-10: #181818;
    --color-grey-11: #6b6e84;
    --color-neutral-5: #c1c4d6;
    --color-neutral-10: #3c405a;
    --color-blue-50: #edf7ff;
    --color-blue-600: #1e68aa;
    --color-error: #e60000;
    --color-errorLite: #fbeae9;
    --color-error-700: #9e0a05;
    --color-warning: #dd900d;
    --color-success-50: #e7f6ec;
    --color-success-900: #004617;
    --color-green: #04802e;
    --color-green-100: #cbe8c1;
    --color-greenLite: #e7f6ec;
    --color-green-700: #335029;
    --color-yellow: #fff9f1;
    --color-pink: #cc658a;
    --color-blue: #2582d4;
    --color-blue-100: #b9ddfe;
    --color-blue-500: #2582d4;
    --color-blue-800: #0f3455;
    --color-blue-700: #164e7f;
    --color-blueLite: #cae6fe;
    --color-pink-800: #4c192c;
    --color-yellow-75: #ffeed4;
    --color-yellow-100: #ffe8c6;
    --color-yellow-700: #91692d;
    --color-yellow-900: #482b00;
    --color-yellowLite: #ffeed4;
    --color-light-black: #040404;
    --color-light-black-80: #1c1c1ccc;
    --color-neutral-200: #edeff5;
    --color-text-200: #a0a3ad;
    --color-purple-5: #7c57ff;
    --color-neutral-0: #fafbff;
    --color-neutral-6: #8f95b2;
    --color-neutral-8: #5b5f76;
    /* ... */
  }
  :root[theme='dark'] {
    --color-bkg: 217deg 32.6% 17.5%;
    --color-content: 210deg 40% 98%;
    /* ... */
  }
}

.wrapper {
  white-space: pre-wrap;
  padding: 26px 16px;
  padding-bottom: 20px;
}

@media (min-width: 640px) {
  .wrapper {
    margin-left: 72px;
    height: 100%;
    padding-bottom: 40px;
  }
}

.custom-scroll-bar {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
